import * as React from 'react';
import { SiteLayout } from '../../updated/components/common';
import Header from '../../updated/components/ru/sections/header/header';
import Footer from '../../updated/components/ru/sections/footer/footer';
import { NavPopup } from '../../updated/components/ru/popups';

export default function PrivacyPolicy() {
	// Добавляем состояние для управления бургер-меню
	const [navPopupOpen, setNavPopupOpen] = React.useState(false);
	const openNavPopup = () => setNavPopupOpen(true);
	const closeNavPopup = () => setNavPopupOpen(false);

	return (
		<SiteLayout
			pageTitle='Политика конфиденциальности | TRAFFLAB'
			siteLang='ru'
		>
			<Header openNavPopupHandler={openNavPopup} />
			<div
				className='container'
				style={{
					color: 'white',
					padding: '40px 20px',
					maxWidth: '1200px',
					margin: '0 auto',
				}}
			>
				<h1>Политика конфиденциальности</h1>
				<div style={{ lineHeight: '1.6', fontSize: '20px' }}>
					<h2>Последнее обновление: 11 марта 2024 г.</h2>

					<p>
						Настоящая Политика конфиденциальности описывает, как
						собираются и обрабатываются ваши персональные данные,
						когда вы, физическое лицо, являетесь пользователем
						веб-сайта _____ (далее "Веб-сайт") или связанных с ним
						dApps.
					</p>

					<p>
						Чтобы упростить чтение этой Политики конфиденциальности,
						наш Веб-сайт, dApps, контент сервиса и все связанные
						функции, услуги, контент и приложения, которые мы можем
						предоставить вам время от времени, и т.д., совместно
						именуются "Услуги".
					</p>

					<p>
						Мы просим вас внимательно прочитать эту Политику
						конфиденциальности, которая была последний раз обновлена
						в указанную выше дату (далее "Политика
						конфиденциальности"), поскольку она содержит важную
						информацию о том, кто мы, как и почему мы собираем,
						храним, используем, передаем и делимся персональной
						информацией, о ваших правах в отношении вашей
						персональной информации, и о том, как связаться с нами и
						надзорными органами в случае, если у вас есть жалоба.
						Эту Политику конфиденциальности следует читать вместе с
						любым отдельным соглашением о продукте или услуге,
						заключенным между нами время от времени, и в дополнение
						к нему.
					</p>

					<p>
						Всякий раз, когда мы ссылаемся на "закон" в рамках
						настоящей Политики конфиденциальности, мы имеем в виду
						все законы с учетом периодически вносимых в них
						изменений. Если мы использовали, но не объяснили
						значение определенного термина в настоящей Политике
						конфиденциальности, этот термин имеет то же значение,
						что и в Условиях использования. Когда мы ссылаемся на
						"информацию" или "данные" в рамках настоящей Политики
						конфиденциальности, мы имеем в виду вашу личную
						информацию.
					</p>

					<p>
						Мы собираем вашу личную информацию, когда вы посещаете,
						используете или взаимодействуете с нами онлайн, а также
						через нашу рекламу, отображаемую через онлайн-сервисы,
						управляемые нами или не связанными с нами третьими
						сторонами. Мы можем использовать или делиться собранной
						личной информацией для предоставления вам продуктов и
						услуг, а также в рекламных целях.
					</p>

					<p>
						Просматривая, получая доступ или используя Услуги, вы
						настоящим безоговорочно соглашаетесь с настоящей
						Политикой конфиденциальности.
					</p>

					<p>
						Термины "Компания", "мы", "нас", "наш" или _____
						относятся к _____, компании с регистрационным номером
						_____, зарегистрированной по адресу: _____.
					</p>

					<p>
						Настоящая Политика конфиденциальности регулируется
						применимыми законами и нормативными актами Англии и
						Уэльса.
					</p>

					<p>
						Для пользователей из ЕС применяются положения Общего
						регламента по защите данных (Регламент (ЕС) 2016/679)
						(GDPR) и Закона о защите данных 2018 года.
					</p>

					<p>
						Компания прилагает максимальные усилия для защиты вашей
						конфиденциальности. Компания использует собранную о вас
						информацию для выполнения своих договорных обязательств
						и улучшения обслуживания клиентов.
					</p>

					<p>
						Все термины, имеющие определения в Условиях
						использования, имеют то же значение в настоящей Политике
						конфиденциальности. "Персональные данные" и "Личная
						информация" означают любую информацию, относящуюся к
						идентифицированному или идентифицируемому живому
						человеку.
					</p>

					<p>
						Мы запрашиваем ваше согласие, когда это необходимо, в
						противном случае, используя наши Услуги, вы соглашаетесь
						на сбор, использование и обмен вашей личной информацией
						в соответствии с применимыми законами и другими
						уведомлениями, которые вы могли получить на основании
						ваших отношений с нами.
					</p>

					<p style={{ fontWeight: 'bold' }}>
						ПОЖАЛУЙСТА, ВНИМАТЕЛЬНО ПРОЧИТАЙТЕ ПОЛИТИКУ
						КОНФИДЕНЦИАЛЬНОСТИ ПЕРЕД СОЗДАНИЕМ ВАШЕЙ УЧЕТНОЙ ЗАПИСИ.
						ЕСЛИ ВЫ НЕ СОГЛАСНЫ СО ВСЕМИ ИЛИ ЛЮБЫМИ ИЗ ЭТИХ УСЛОВИЙ,
						ПОЖАЛУЙСТА, НЕМЕДЛЕННО ПОКИНЬТЕ ВЕБ-САЙТ ИЛИ DAPP
					</p>

					<h2>1. СБОР ВАШИХ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

					<h3>1.1. Персональные данные, которые мы можем собирать</h3>

					<p>
						Категории персональной информации, которую мы собираем,
						зависят от того, как вы взаимодействуете с нами, нашими
						Услугами и требованиями применимого законодательства. Мы
						собираем информацию, которую вы предоставляете нам,
						информацию, которую мы получаем автоматически, когда вы
						используете наши Услуги, и информацию из других
						источников, таких как сторонние сервисы и организации,
						как описано ниже.
					</p>

					<h4>
						1.1.1 Информация, которую вы предоставляете нам напрямую
					</h4>

					<p>
						Мы можем собирать следующую персональную информацию,
						которую вы нам предоставляете:
					</p>

					<ul style={{ paddingLeft: '20px' }}>
						<li>
							<strong>Создание учетной записи.</strong> Мы можем
							собирать информацию при создании вами учетной записи
							у нас, такую как имя и адрес электронной почты, а
							также ваш ID мобильных денег. Мы также можем
							потребовать, чтобы вы предоставили дополнительную
							информацию для идентификации и проверки.
						</li>
						<li>
							<strong>Информация о транзакциях.</strong> Для
							совершения транзакций в рамках Услуг вам может
							потребоваться предоставить нам или нашим третьим
							сторонам информацию о вашем платеже или информацию о
							вашем цифровом кошельке и т.д. Мы никогда не будем
							запрашивать или собирать ваши приватные ключи.
						</li>
						<li>
							<strong>Другие транзакции.</strong> Мы можем
							собирать персональную информацию и детали, связанные
							с вашей деятельностью в наших Услугах.
						</li>
						<li>
							<strong>Ваше общение с нами.</strong> Мы можем
							собирать персональную информацию, такую как адрес
							электронной почты, когда вы запрашиваете информацию
							о наших Услугах или иным образом общаетесь с нами.
						</li>
						<li>
							<strong>Интерактивные функции.</strong> Мы и другие
							пользователи наших Услуг можем собирать личную
							информацию, которую вы отправляете или делаете
							доступной через наши интерактивные функции
							(например, через сообщество в Discord, функции
							обмена сообщениями и чата, а также страницы в
							социальных сетях). Любая личная информация, которую
							вы предоставляете в публичных разделах этих функций,
							будет считаться "публичной" ("Пользовательский
							контент"), если иное не требуется применимым
							законодательством, и не подпадает под защиту
							конфиденциальности, упомянутую в настоящем
							документе. Пожалуйста, будьте осторожны, прежде чем
							раскрывать любую информацию, которая может
							идентифицировать вас в реальном мире другим
							пользователям.
						</li>
						<li>
							<strong>Опросы.</strong> Мы можем связаться с вами
							для участия в опросах. Если вы решите участвовать,
							вас могут попросить предоставить определенную
							информацию, которая может включать личную
							информацию.
						</li>
						<li>
							<strong>
								Развитие бизнеса и стратегические партнерства.
							</strong>{' '}
							Мы можем собирать личную информацию от физических
							лиц и третьих сторон для оценки и реализации
							потенциальных бизнес-возможностей.
						</li>
					</ul>

					<h4>1.1.2 Информация, собираемая автоматически</h4>

					<p>
						Мы можем автоматически собирать личную информацию, когда
						вы используете наши Услуги:
					</p>

					<ul style={{ paddingLeft: '20px' }}>
						<li>
							<strong>Автоматический сбор данных.</strong> Мы
							можем автоматически собирать определенную информацию
							при использовании вами наших Услуг, такую как ваш
							Интернет-протокол (IP) адрес, пользовательские
							настройки, MAC-адрес, идентификаторы файлов cookie,
							мобильный оператор, мобильная реклама и другие
							уникальные идентификаторы, информация о браузере или
							устройстве, информация о местоположении (включая
							приблизительное местоположение, полученное из
							IP-адреса), Интернет-провайдер. Мы также можем
							автоматически собирать информацию о вашем
							использовании наших Услуг, такую как страницы,
							которые вы посещаете до, во время и после
							использования наших Услуг, информацию о ссылках, по
							которым вы нажимаете, типы контента, с которым вы
							взаимодействуете, частоту и продолжительность вашей
							деятельности, и другую информацию о том, как вы
							используете наши Услуги.
						</li>
						<li>
							<strong>
								Политика использования файлов cookie, пиксельных
								тегов/веб-маяков и других технологий.
							</strong>{' '}
							Мы, а также третьи стороны, предоставляющие контент,
							рекламу или другие функции на наших Услугах, можем
							использовать файлы cookie, пиксельные теги,
							локальное хранилище и другие технологии
							("Технологии") для автоматического сбора информации
							через ваше использование наших Услуг.
						</li>
						<li>
							<strong>Файлы cookie.</strong> Файлы cookie - это
							небольшие текстовые файлы, размещаемые в браузерах
							устройств, которые хранят предпочтения и облегчают,
							и улучшают ваш опыт.
						</li>
						<li>
							<strong>
								Пиксельные теги/веб-маяки и другие технологии.
							</strong>{' '}
							Пиксельный тег (также известный как веб-маяк) - это
							фрагмент кода, встроенный в наши Услуги, который
							собирает информацию о взаимодействии с нашими
							Услугами. Использование пиксельного тега позволяет
							нам записывать, например, что пользователь посетил
							определенную веб-страницу или нажал на определенную
							рекламу. Мы также можем включать веб-маяки в
							электронные письма, чтобы понять, были ли сообщения
							открыты, выполнены действия или переадресованы.
						</li>
					</ul>

					<p>
						Наше использование этих Технологий подпадает под
						следующие общие категории:
					</p>

					<ul style={{ paddingLeft: '20px' }}>
						<li>
							<strong>Операционно необходимые.</strong> Это
							включает Технологии, которые позволяют вам получить
							доступ к нашим Услугам, приложениям и инструментам,
							которые необходимы для выявления нерегулярного
							поведения веб-сайта, предотвращения мошеннической
							деятельности, повышения безопасности или позволяют
							вам использовать нашу функциональность;
						</li>
						<li>
							<strong>Связанные с производительностью.</strong> Мы
							можем использовать Технологии для оценки
							производительности наших Услуг, в том числе в рамках
							наших аналитических практик, чтобы помочь нам
							понять, как люди используют наши Услуги (см.
							"Аналитика" ниже);
						</li>
						<li>
							<strong>Связанные с функциональностью.</strong> Мы
							можем использовать Технологии, которые позволяют нам
							предложить вам расширенную функциональность при
							доступе или использовании наших Услуг. Это может
							включать идентификацию вас при входе в наши Услуги
							или отслеживание ваших указанных предпочтений,
							интересов и т.д.;
						</li>
						<li>
							<strong>
								Связанные с рекламой или таргетингом.
							</strong>{' '}
							Мы можем использовать собственные или сторонние
							Технологии для доставки контента, включая рекламу,
							соответствующую вашим интересам, на наших Услугах
							или на сторонних веб-сайтах.
						</li>
					</ul>

					<p>
						См. "Ваш выбор конфиденциальности и права" ниже, чтобы
						понять ваш выбор в отношении этих Технологий.
					</p>

					<h4>Ваш выбор конфиденциальности</h4>

					<p>
						Выбор конфиденциальности, который вы можете иметь в
						отношении вашей личной информации, определяется
						применимым законодательством и описан ниже.
					</p>

					<ul style={{ paddingLeft: '20px' }}>
						<li>
							<strong>Электронные сообщения.</strong> Если вы
							получаете нежелательное электронное письмо от нас,
							вы можете использовать ссылку для отказа от
							подписки, найденную внизу электронного письма, чтобы
							отказаться от получения будущих электронных писем.
							Обратите внимание, что вы будете продолжать получать
							электронные письма, связанные с переводами,
							относительно услуг, которые вы запросили. Мы также
							можем отправлять вам определенные непромоционные
							сообщения относительно нас и наших Услуг, и вы не
							сможете отказаться от этих сообщений (например,
							сообщения относительно наших Услуг или обновлений
							наших Условий использования или настоящей Политики
							конфиденциальности).
						</li>
						<li>
							<strong>"Не отслеживать".</strong> "Не отслеживать"
							("DNT") - это предпочтение конфиденциальности,
							которое пользователи могут установить в определенных
							веб-браузерах. Обратите внимание, что мы не
							реагируем и не учитываем сигналы DNT или аналогичные
							механизмы, передаваемые веб-браузерами.
						</li>
						<li>
							<strong>
								Файлы cookie и реклама на основе интересов.
							</strong>{' '}
							Вы можете остановить или ограничить размещение
							Технологий на вашем устройстве или удалить их,
							настроив свои предпочтения, как позволяет ваш
							браузер или устройство. Однако, если вы настроите
							свои предпочтения, наши Услуги могут работать
							неправильно. Обратите внимание, что отказы на основе
							файлов cookie не эффективны в мобильных приложениях.
							Однако вы можете отказаться от персонализированной
							рекламы в некоторых мобильных приложениях, следуя
							инструкциям для Android, iOS и других. Обратите
							внимание, что вы должны отдельно отказаться в каждом
							браузере и на каждом устройстве.
						</li>
					</ul>

					<p>
						Индустрия онлайн-рекламы также предоставляет веб-сайты,
						с которых вы можете отказаться от получения целевой
						рекламы от партнеров по данным и других рекламных
						партнеров, которые участвуют в программах
						саморегулирования. Вы можете получить доступ к ним и
						узнать больше о целевой рекламе, выборе потребителей и
						конфиденциальности, посетив Network Advertising
						Initiative, Digital Advertising Alliance, European
						Digital Advertising Alliance и Digital Advertising
						Alliance of Canada.
					</p>

					<h4>Ваши права на конфиденциальность</h4>

					<p>
						В соответствии с применимым законодательством вы можете
						иметь право:
					</p>

					<ul style={{ paddingLeft: '20px' }}>
						<li>
							<strong>
								Доступ к личной информации о вас, включая:
							</strong>{' '}
							(i) подтверждение того, обрабатываем ли мы вашу
							личную информацию; (ii) получение доступа к вашей
							личной информации или ее копии; или (iii) получение
							электронной копии личной информации, которую вы
							предоставили нам, или просьба о том, чтобы мы
							отправили эту информацию другой компании (право на
							переносимость данных);
						</li>
						<li>
							<strong>
								Запрос на исправление вашей личной информации,
							</strong>{' '}
							если она неточна или неполна. В некоторых случаях мы
							можем предоставить инструменты самообслуживания,
							которые позволяют вам обновлять вашу личную
							информацию;
						</li>
						<li>
							<strong>
								Запрос на удаление вашей личной информации;
							</strong>
						</li>
						<li>
							<strong>
								Запрос на ограничение или возражение против
								обработки нами вашей личной информации,
							</strong>{' '}
							включая случаи, когда обработка вашей личной
							информации основана на нашем законном интересе или
							для целей прямого маркетинга; и
						</li>
						<li>
							<strong>
								Отзыв вашего согласия на обработку нами вашей
								личной информации.
							</strong>{' '}
							Обратите внимание, что ваш отзыв будет действовать
							только для будущей обработки и не повлияет на
							законность обработки до отзыва.
						</li>
					</ul>

					<p>
						Если вы хотите воспользоваться любым из этих прав,
						пожалуйста, свяжитесь с нами. Мы будем обрабатывать
						такие запросы в соответствии с применимыми законами.
					</p>

					<h4>Аналитика</h4>

					<p>
						Мы можем использовать наши Технологии и другие сторонние
						инструменты для обработки аналитической информации о
						наших Услугах. Эти технологии позволяют нам обрабатывать
						данные об использовании, чтобы лучше понять, как
						используются наши Услуги, и постоянно улучшать и
						персонализировать наши Услуги. Некоторые из наших
						аналитических партнеров включают:
					</p>

					<ul style={{ paddingLeft: '20px' }}>
						<li>
							<strong>Google Analytics.</strong> Для получения
							дополнительной информации о том, как Google
							использует ваши данные (в том числе для собственных
							целей, например, для профилирования или связывания с
							другими данными), посетите Политику
							конфиденциальности Google Analytics.
						</li>
					</ul>

					<h4>Платформы социальных сетей</h4>

					<p>
						Наши Услуги могут содержать кнопки социальных сетей,
						таких как Discord, Snapchat, Twitter и Telegram, которые
						могут включать виджеты, такие как кнопка "поделиться
						этим" или другие интерактивные мини-программы). Эти
						функции могут собирать ваш IP-адрес и информацию о том,
						какую страницу вы посещаете на наших Услугах, и могут
						устанавливать файл cookie для правильного
						функционирования функции. Ваши взаимодействия с этими
						платформами регулируются политикой конфиденциальности
						компании, предоставляющей их.
					</p>

					<h4>1.1.3 Информация, собираемая из других источников</h4>

					<p>
						<strong>Сторонние источники.</strong> Мы можем получать
						информацию о вас из других источников, в том числе через
						сторонние сервисы и организации. Например, если вы
						получаете доступ к нашим Услугам через стороннее
						приложение, такое как магазин приложений, сторонний
						сервис входа или сайт социальной сети, мы можем собирать
						информацию о вас из этого стороннего приложения, которую
						вы сделали доступной через ваши настройки
						конфиденциальности.
					</p>

					<h2>2. ИСПОЛЬЗОВАНИЕ ВАШИХ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

					<h3>2.1. Мы используем ваши данные для следующих целей:</h3>

					<ul style={{ paddingLeft: '20px' }}>
						<li>
							2.1.1 Для выполнения обязательств и предоставления
							вам услуг, на которые мы получили ваше согласие;
						</li>
						<li>
							2.1.2 Для понимания ваших потребностей и
							предпочтений при использовании наших Услуг;
						</li>
						<li>
							2.1.3 Для разработки новых и улучшения существующих
							предложений услуг и продуктов, для уведомления вас
							об изменениях, связанных с нашими Услугами;
						</li>
						<li>
							2.1.4 Для проверки и подтверждения личности
							пользователей, которые открывают и управляют
							счетами, с целью предотвращения мошенничества,
							обмана и других незаконных действий;
						</li>
						<li>
							2.1.5 Для соблюдения юридических требований по
							борьбе с отмыванием денег и финансированием
							терроризма;
						</li>
						<li>
							2.1.6 Для обеспечения безопасности нашего Веб-сайта,
							наших Услуг и вашей учетной записи;
						</li>
						<li>
							2.1.7 Для поддержки, ответа и разрешения ваших жалоб
							и проблем, связанных с использованием наших Услуг и
							возможностей нашего Веб-сайта или наших dApps.
						</li>
						<li>
							2.1.8 Другие цели. Мы также используем вашу
							информацию для других целей по вашему запросу или в
							соответствии с применимым законодательством.
						</li>
					</ul>

					<h4>Автоматизированное принятие решений</h4>

					<p>
						Мы можем заниматься автоматизированным принятием
						решений, включая профилирование. Наша обработка вашей
						личной информации не приведет к решению, основанному
						исключительно на автоматизированной обработке, которое
						существенно влияет на вас, если только такое решение не
						является необходимой частью договора, который мы имеем с
						вами, у нас есть ваше согласие, или нам разрешено
						законом заниматься таким автоматизированным принятием
						решений. Если у вас есть вопросы о нашем
						автоматизированном принятии решений, вы можете связаться
						с нами.
					</p>

					<h4>Деидентифицированная и агрегированная информация</h4>

					<p>
						Мы можем использовать личную информацию и другую
						информацию о вас для создания деидентифицированной и/или
						агрегированной информации, такой как
						деидентифицированная информация о местоположении,
						информация об устройстве, с которого вы получаете доступ
						к нашим Услугам, или другие анализы, которые мы создаем.
					</p>

					<h3>
						2.2. Мы используем агрегированные персональные данные
						для понимания того, как наши пользователи используют
						наши Услуги, при условии, что эти данные не могут
						идентифицировать какое-либо физическое лицо. Мы также
						используем сторонние инструменты веб-аналитики, которые
						помогают нам понять, как пользователи взаимодействуют с
						нашим веб-сайтом.
					</h3>

					<h2>3. РАСКРЫТИЕ ВАШИХ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

					<h3>
						3.1 Мы можем делиться вашей информацией с избранными
						третьими сторонами, включая:
					</h3>

					<ul style={{ paddingLeft: '20px' }}>
						<li>
							3.1.1 деловых партнеров, поставщиков и независимых
							подрядчиков для выполнения любого договора, который
							мы заключаем с ними или с вами;
						</li>
						<li>
							3.1.2 рекламодателей и рекламные сети (только
							агрегированные персональные данные);
						</li>
						<li>
							3.1.3 поставщиков аналитики и поисковых систем,
							которые помогают нам в улучшении и оптимизации
							услуг;
						</li>
					</ul>

					<h3>
						3.2 Мы также можем раскрыть ваши персональные данные,
						если:
					</h3>

					<ul style={{ paddingLeft: '20px' }}>
						<li>
							3.2.1 если мы обязаны раскрыть или поделиться вашими
							персональными данными в целях соблюдения любого
							юридического обязательства, или в целях обеспечения
							соблюдения или применения наших Условий
							использования или других условий; или для защиты
							прав, собственности или наших клиентов, или других
							лиц. Это включает обмен информацией с другими
							компаниями и организациями в целях защиты от
							мошенничества и снижения кредитного риска.
						</li>
					</ul>

					<h2>
						4. БЕЗОПАСНОСТЬ И ХРАНЕНИЕ ВАШИХ ПЕРСОНАЛЬНЫХ ДАННЫХ
					</h2>

					<h3>
						4.1. Мы внедрили меры безопасности для обеспечения
						конфиденциальности ваших персональных данных и для
						защиты ваших данных от потери, неправомерного
						использования, изменения или уничтожения. Только
						уполномоченные представители имеют доступ к вашим
						персональным данным, и эти представители обязаны
						обращаться с информацией как с конфиденциальной. Меры
						безопасности, действующие в настоящее время, будут время
						от времени пересматриваться в соответствии с правовыми и
						техническими разработками.
					</h3>

					<p>
						4.2. К сожалению, передача информации через Интернет не
						является полностью безопасной. Хотя мы сделаем все
						возможное для защиты ваших персональных данных, любая
						передача осуществляется на ваш собственный риск. После
						получения вашей информации мы будем использовать строгие
						процедуры и функции безопасности, чтобы попытаться
						предотвратить несанкционированный доступ.
					</p>

					<p>
						4.3. Мы не будем продавать, сдавать в аренду или
						передавать вашу информацию третьим сторонам. Однако мы
						можем делиться вашей информацией с доверенными третьими
						сторонами, чтобы помочь нам выполнить статистический
						анализ, отправить вам электронную почту, предоставить
						поддержку клиентов или организовать доставку. Все такие
						третьи стороны запрещено использовать вашу личную
						информацию, кроме как для предоставления этих услуг нам,
						и они обязаны сохранять конфиденциальность вашей
						информации.
					</p>

					<p>
						4.4. Наш веб-сайт может время от времени содержать
						ссылки на веб-сайты наших партнерских сетей,
						рекламодателей и аффилированных лиц и с них. Если вы
						перейдете по ссылке на любой из этих веб-сайтов,
						обратите внимание, что эти веб-сайты имеют свои
						собственные политики конфиденциальности, и что мы не
						несем никакой ответственности за эти политики.
						Пожалуйста, проверьте эти политики, прежде чем
						отправлять какие-либо персональные данные на эти
						веб-сайты.
					</p>

					<p>
						4.5. Мы будем хранить вашу личную информацию до тех пор,
						пока вы являетесь клиентом _____. Мы можем хранить вашу
						личную информацию до 5 лет после того, как вы
						перестанете быть клиентом. Причины, по которым мы можем
						это делать:
					</p>

					<ul style={{ paddingLeft: '20px' }}>
						<li>
							для ответа на вопрос или жалобу, или чтобы показать,
							предоставили ли мы вам справедливое обращение;
						</li>
						<li>
							для изучения данных клиентов в рамках нашего
							собственного исследования;
						</li>
						<li>
							для соблюдения юридических правил, которые
							применяются к нам в отношении ведения записей.
						</li>
					</ul>

					<p>
						Мы также можем хранить ваши данные дольше 5 лет, если
						определенные законы означают, что мы не можем удалить их
						по юридическим, нормативным или техническим причинам.
					</p>

					<h2>5. МЕЖДУНАРОДНАЯ ПЕРЕДАЧА ДАННЫХ</h2>

					<p>
						Вся информация, обрабатываемая нами, может быть
						передана, обработана и сохранена в любом месте мира,
						включая, но не ограничиваясь, Соединенные Штаты или
						другие страны, которые могут иметь законы о защите
						данных, отличные от законов места вашего проживания. Мы
						стремимся защитить вашу информацию в соответствии с
						требованиями применимых законов.
					</p>

					<h2>6. РАЗНОЕ</h2>

					<p>
						6.1. Политика конфиденциальности остается в полной силе
						и действии, пока вы используете Веб-сайт.
					</p>

					<p>
						6.2 Компания оставляет за собой право изменять Политику
						конфиденциальности в любое время по собственному
						усмотрению Компании. Они уведомят вас о поправках
						посредством автоматического уведомления на Веб-сайте, но
						не будут запрашивать какие-либо действия для
						подтверждения вашего согласия. Если вы не согласны с
						новой измененной версией, то вы должны немедленно
						прекратить доступ к Веб-сайту и прекратить использование
						всех Услуг. Если вы продолжаете использовать услуги,
						измененная Политика конфиденциальности имеет юридическую
						силу для вас, и ваши действия будут представлять собой
						принятие поправок.
					</p>

					<p>
						6.4. Если вы находитесь в Европейской экономической
						зоне, Швейцарии или Соединенном Королевстве, вы имеете
						право подать жалобу в надзорный орган, если считаете,
						что наша обработка вашей личной информации нарушает
						применимое законодательство.
					</p>

					<p>
						6.5. Услуги не предназначены для детей младше 18 лет
						(или другого возраста, требуемого местным
						законодательством), и мы сознательно не собираем личную
						информацию от детей. Если вы узнаете, что ваш ребенок
						предоставил нам личную информацию без вашего согласия,
						вы можете связаться с нами. Если мы узнаем, что мы
						собрали личную информацию ребенка в нарушение
						применимого законодательства, мы незамедлительно
						предпримем шаги для удаления такой информации.
					</p>

					<p>
						6.6. Вы можете отозвать свое согласие на обработку нами
						вашей информации в любое время. Пожалуйста, свяжитесь с
						нами, если вы хотите это сделать, по адресу: _____. Это
						повлияет только на способ использования нами информации,
						когда наша причина обработки вашей информации
						заключается в том, что вы предоставили свое согласие на
						это использование. Если вы отзываете свое согласие, мы
						можем быть не в состоянии предоставить вам определенные
						услуги. Если это так, мы сообщим вам об этом. Затем у
						вас есть возможность снова дать нам свое согласие, если
						вы хотите получить доступ к нашим продуктам или услугам.
					</p>

					<p>
						6.7. Мы будем применять адекватные технические и
						организационные меры безопасности для защиты вашей
						личной информации. Мы будем прилагать разумные усилия
						для обеспечения того, чтобы ваши персональные данные
						были точными, полными и актуальными. Пожалуйста,
						убедитесь, что вы уведомляете нас без неоправданной
						задержки о любых изменениях в персональных данных,
						которые вы предоставили нам, обновив свои данные или
						связавшись с нами по контактным данным, указанным в
						настоящей Политике конфиденциальности.
					</p>

					<p>
						6.8. Получая доступ к Веб-сайту или используя любой из
						наших продуктов или услуг, вы соглашаетесь и разрешаете
						экспорт персональных данных в Компанию, а также их
						хранение и использование, как указано в настоящей
						Политике конфиденциальности.
					</p>

					<p>
						Настоящая Политика конфиденциальности регулируется,
						толкуется и вступает в силу в соответствии с законами
						Англии и Уэльса, применимыми к договорам, которые
						считаются заключенными в пределах такого государства,
						без учета выбора закона или положений о коллизии
						правовых норм. Исключительная юрисдикция для всех споров
						между вами и Компанией будет принадлежать
						государственным и федеральным судам, расположенным в
						Англии и Уэльсе, и вы и Компания отказываетесь от любых
						возражений против юрисдикции и места проведения в таких
						судах.
					</p>

					<p style={{ fontWeight: 'bold' }}>
						НАСТОЯЩИМ ВЫ ОТКАЗЫВАЕТЕСЬ ОТ СВОЕГО ПРАВА НА СУДЕБНОЕ
						РАЗБИРАТЕЛЬСТВО С УЧАСТИЕМ ПРИСЯЖНЫХ В ОТНОШЕНИИ ЛЮБОЙ
						ПРЕТЕНЗИИ, ДЕЙСТВИЯ ИЛИ РАЗБИРАТЕЛЬСТВА, ПРЯМО ИЛИ
						КОСВЕННО ВОЗНИКАЮЩИХ ИЗ НАСТОЯЩЕГО СОГЛАШЕНИЯ ИЛИ
						СВЯЗАННЫХ С НИМ, В МАКСИМАЛЬНОЙ СТЕПЕНИ, РАЗРЕШЕННОЙ
						ЗАКОНОМ.
					</p>

					<h2>7. КОНТАКТНАЯ ИНФОРМАЦИЯ</h2>

					<p>
						7.9. Мы несем ответственность как "Контролер" этой
						личной информации для целей этих законов. Если у вас
						есть какие-либо вопросы об этой Политике
						конфиденциальности или о том, как мы (можем) собирать,
						хранить или использовать вашу информацию, пожалуйста,
						свяжитесь с нами по электронной почте по адресу: _____.
					</p>
				</div>
			</div>
			<Footer />

			{/* Добавляем компонент NavPopup */}
			<NavPopup isOpen={navPopupOpen} closeHandler={closeNavPopup} />
		</SiteLayout>
	);
}
